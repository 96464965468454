import React from "react";
import logofooter from "../../Assets/Images/Logofooter.svg"

import location from "../../Assets/Images/map-pin.svg"
import email from "../../Assets/Images/mail.svg"
import phone from "../../Assets/Images/phone.svg"

import IconFacebook from "../../Assets/Images/Facebook Icon.svg"
import IconInstagram from "../../Assets/Images/Instagram Icon.svg"
import IconLinkedin from "../../Assets/Images/Linkedin Icon.svg"

export default function App() {

        return (
            <div id="" className="bg-white px-10 lg:px-20 flex flex-col py-20 space-y-8 justify-between h-auto">
                <div className={"lg:w-7/12 py-10 mx-auto flex flex-col space-y-3"}>
                    <img alt={"lgft"} src={logofooter} className={"mx-auto w-5/12"}/>
                    <h1 className={"text-h1 text-4xl sm:pb-5 md:pb-5 lg:pb-0 mx-auto text-center"}>
                        Adiwisista. We're here.
                    </h1>
                    <p className={"text-center text-zinc-400 text-sm"}>
                        Equitable Solutions for Digital Financial Life
                        <br/>
                        Our technology is based on a platform that has been proven and audited by the authority.
                    </p>
                </div>
                
                <div className={"space-y-8 md:space-y-0 w-full flex flex-col md:flex-row justify-between items-center md:items-start"}>
                    <div id="location" className={"flex flex-col md:flex-row w-8/12 md:w-4/12 flex-1 items-center md:items-start gap-3"}>
                        <img alt="location" className={"relative top-1"} src={location}/>
                        <p className={"flex text-sm leading-relaxed"}>
                            Prosperity Tower
                            <br/> 20th Floor District 8
                            <br/> SCBD Jl. Jend. Sudirman
                            <br/> Jakarta 12190
                            <br/> Indonesia
                        </p>
                    </div>
                    <div id="email" className={"flex flex-col md:flex-row w-8/12 md:w-4/12 flex-1 items-center md:items-start gap-3"}>
                        <img alt="email" className={"relative top-1"} src={email}/>
                        <p className={"flex text-sm leading-loose"}>
                            customer.care@danai.id​
                            <br/> enquiry@adiwisista.com
                        </p>
                    </div>
                    <div id="phone" className={"flex flex-col md:flex-row w-8/12 md:w-4/12 flex-1 items-center md:items-start gap-3"}>
                        <img alt="phone" className={"relative top-1"} src={phone}/>
                        <p className={"flex text-sm leading-loose"}>
                            +62 21 5010 3360
                        </p>
                    </div>
                    <div className={"flex flex-row space-x-3"}>
                        <a href="https://www.facebook.com/danai.indonesia" target="_blank" rel="noreferrer">
                            <img alt={"fb"} src={IconFacebook}/>
                        </a>
                        <a href="https://www.linkedin.com/company/danai-indonesia/" target="_blank" rel="noreferrer">
                            <img alt={"li"} src={IconLinkedin}/>
                        </a>
                        <a href="https://www.instagram.com/danai.id/" target="_blank" rel="noreferrer">
                            <img alt={"ig"} src={IconInstagram}/>
                        </a>
                    </div>
                </div>
                <div id="copyright" className={"mx-auto text-zinc-400 text-sm "}>
                    © 2022 PT Adiwisista Financial Teknologi. All Rights Reserved.
                </div>
            </div>
        );
}
