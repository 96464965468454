import section1 from "../../Assets/Images/section1.png"
import dots from "../../Assets/Images/DOTS.svg"

import illust1 from "../../Assets/Images/illust-simple.svg"
import illust2 from "../../Assets/Images/illust-swift.svg"
import illust3 from "../../Assets/Images/illust-secure.svg"

import content1 from "../../Assets/Images/content1.png"
import content2 from "../../Assets/Images/content2.png"
import content3 from "../../Assets/Images/content3.png"

import content4 from "../../Assets/Images/group1.svg"
import content5 from "../../Assets/Images/group3.svg"
import content6 from "../../Assets/Images/group2.svg"
import content7 from "../../Assets/Images/group4.svg"

import product1 from "../../Assets/Images/screen1.svg"
import product2 from "../../Assets/Images/screen2.svg"
import product3 from "../../Assets/Images/screen3.svg"
import product4 from "../../Assets/Images/screen4.svg"

import section7 from "../../Assets/Images/contact.png"
import arrow from "../../Assets/Images/arrow-up-right.svg"
import playstore from "../../Assets/Images/google-play.png"
import appstore from "../../Assets/Images/app-store.svg"

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import '../../index.css'
import React from "react";
import {TimelineMax, TimelineLite} from "gsap/gsap-core";

export default function App() {

    gsap.registerPlugin(ScrollTrigger, TimelineMax, TimelineLite)
    const { useLayoutEffect, useRef } = React;
    const comp = useRef();
    const animSection1 = document.getElementById("#section1")
    const animSection2 = document.getElementById("#section2")
    const animSection3 = document.getElementById("#section3")
    const animSection4 = document.getElementById("#section4")
    const animSection5 = document.getElementById("#section5")
    // const animSection6 = document.getElementById("#section6")







    useLayoutEffect(() => {

        // section1 var
        gsap.set("#imgsection1",{
                translateX:-1000,
                opacity:0,
            })
        gsap.set("#textingsection1",{
                opacity:0,
            })
        function textTLon(){
                const textTL1 = new TimelineMax({ pause: true});
                const imgTL1 = new TimelineMax({ pause: true});

                imgTL1.to('#imgsection1', {
                    translateX:0,
                    duration:0.5,
                    opacity:1,
                })
                textTL1.to('#textingsection1', {duration:0.5, opacity:1,})
            }

        // section2 var
        gsap.set("#section2image",{
                translateX:+1000,
                opacity:0,
            })
        gsap.set("#section2text",{
                opacity:0,
            })
        function section2TLon(){
                const textTL1 = new TimelineMax({ pause: true});
                const imgTL1 = new TimelineMax({ pause: true});

                imgTL1.to('#section2image', {
                    translateX:0,
                    duration:1,
                    opacity:1,
                })
                textTL1.to('#section2text', {duration:2, opacity:1,})
            }

        // section3 var
        gsap.set("#section3text",{
                opacity:0,
            })
        gsap.set(".box",{
                y:-300,
                opacity:0,
            })
        function section3TLon(){
                const textTL1 = new TimelineMax({ pause: true});
                const boxesTL = new TimelineMax({ pause: true});
                const boxes = gsap.utils.toArray('.box');

                boxes.forEach(box => {
                    boxesTL.to(box, {
                        y: 0,
                        opacity:1,
                        duration:0.5
                    })
                });
                textTL1.to('#section3text', {duration:2, opacity:1,})
            }

        // section4 var
        gsap.set("#section4",{
                opacity:0,
            })
        function section4TLon(){
                const textTL1 = new TimelineMax({ pause: true});

                textTL1.to('#section4', {duration:2, opacity:1,})
            }

        // section5 var
        gsap.set("#section5text",{
                opacity:0,
            })
        gsap.set(".boxsection5",{
                translateX:-300,
                opacity:0,
            })
        function section5TLon(){
                const textTL1 = new TimelineMax({ pause: true});
                const boxesTL = new TimelineMax({ pause: true});
                const boxes = gsap.utils.toArray('.boxsection5');

                boxes.forEach(box => {
                    boxesTL.to(box, {
                        translateX: 0,
                        opacity:1,
                        duration:0.5
                    })
                });
                textTL1.to('#section5text', {duration:2, opacity:1,})
            }

        // scrolltrigger
        let ctx = gsap.context(() => {

            // section1
            ScrollTrigger.create({
                    trigger: animSection1,
                    start:"+=1000 bottom",
                    end:"+=1000 top",

                    onEnter: textTLon,

                });

            // section2
            ScrollTrigger.create({
                    trigger: animSection2,
                    start:"+=1300 bottom",
                    end:"+=1300 top",

                    onEnter: section2TLon,
                });

            // section3
            ScrollTrigger.create({
                    trigger: animSection3,
                    start:"+=1700 bottom",
                    end:"+=1700 top",

                    onEnter: section3TLon,
                });

            // section4
            ScrollTrigger.create({
                    trigger: animSection4,
                    start:"+=2300 bottom",
                    end:"+2700 top",

                    onEnter: section4TLon,
                });

            // section5
            ScrollTrigger.create({
                    trigger: animSection5,
                    start:"+=3000 bottom",
                    end:"+3300 top",

                    onEnter: section5TLon,
                });

            // section6
            // ScrollTrigger.create({
            //         trigger: animSection6,
            //         start:"+=3500 bottom",
            //         end:"+3700 top",

            //         onEnter: section6TLon,
            //     });

            }, comp);

        return () => ctx.revert(); // cleanup

    });

    return (
        <div className={""}>
            <div id={"section1"} className="z-10 bg-white w-full h-fit section1 lg:flex lg:flex-row justify-between ">
                <div className={"lg:flex w-8/12 md:w-5/12 lg:w-4/12"} >
                    <img id="imgsection1" alt={"section1"} className={"w-full object-fit md:object-cover"} src={section1}/>
                </div>
                <div id={"textingsection1"} className={"relative py-10 px-8 my-auto lg:py-0 lg:px-20 w-full lg:w-8/12 flex flex-col space-y-5"} >
                    <img alt={"dots"} className={"block lg:block w-6/12 md:w-4/12 absolute -top-8 right-10"} src={dots}/>
                    <h1 className={"text-h1 text-4xl md:text-5xl"}>
                        About Us
                    </h1>
                    <p className={"text-left leading-relaxed"}>
                        <b className="text-bold">AdiWisista Group</b> was incepted by individuals who believed in that financial services should be available to everybody.
                        <br/>
                        <br/>
                        Indonesia is one of the country in the world where financial inclusion is still considered an area that need a big boost. On the other hand technology adoption is one of the highest in the world. We seek to bridge this gap with the technological bridge. 
                        <br/>
                        <br/>
                        Equipped with extensive experience of our team in financial services industry and the ever youthful zeal in technological innovation, we intend to marry them both to create an experience that defines our competitive edge.
                        <br/>
                        <br/>
                        Currently we have <b className="text-bold">4 business units</b> and more than <b className="text-bold">5 partnerships</b>, and still growing.
                    </p>
                </div>
            </div>

            <div id={"section2"} className="z-10 w-full h-fit section2 flex flex-row justify-center ">
                <div id={"section2image"} className={"relative py-10 px-8 my-auto lg:py-0 lg:px-16 w-full h-fit lg:h-64 flex flex-col space-y-5 items-center"}>
                    <p id={"section2text"} className={"text-shadow-lg text-white text-xl md:text-2xl lg:text-3xl my-auto w-full lg:w-7/12 leading-relaxed"}>
                        The platform will enable an end to end solution from web based front end to mobile based until the bank end solutions.
                    </p>
                </div>
            </div>

            <div id={"section3text"} className="z-10 bg-white w-full h-fit section3 py-10 relative flex flex-row justify-between ">
                    {/* <img alt={"dots"} className={"hidden lg:block absolute top-10 right-10"} src={dots}/> */}
                    <div id={""} className={"relative py-10 px-8 lg:py-10 lg:px-16 w-full flex flex-col"}>
                        <h1 id={"section3text"} className={"text-h1 text-4xl md:text-5xl pb-12"}>
                            Our Design Philosophy
                        </h1>

                        <div className={"w-full flex flex-col lg:flex-row space-x-0 space-y-16 lg:space-x-20 lg:space-y-0 justify-between"}>
                            <div className={"box flex flex-col space-y-6 lg:space-y-10"}>
                                <img alt={"illust1"} className={"w-20 lg:flex md:w-24 object-contain"} src={illust1}/>
                                <h2 className={"text-h2-green text-2xl lg:text-[40px]"}>
                                    Simple
                                </h2>
                                <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                                <div className={"flex flex-col space-y-5"}>
                                    <p className={"text-left italic text-bold"}>
                                        Life is complicated, we made it simple.
                                    </p>
                                    <p className={"text-left"}>
                                        Our solutions put the user front and center. We believe complicated financial services should be made as simple as possible so that it is availed to every walks of life the way it should.
                                    </p>
                                    <p className={"text-left"}>
                                        Adiwisista provides the platform for the creation of complete financial services and yet maintain its simplicity.
                                    </p>
                                </div>
                            </div>
                            <div className={"box flex flex-col space-y-6 lg:space-y-10"}>
                                <img alt={"illust2"} className={"w-20 lg:flex md:w-24 object-contain"} src={illust2}/>
                                <h2 className="text-h2-blue text-2xl lg:text-[40px]">
                                    Swift
                                </h2>
                                <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                                <div className={"flex flex-col space-y-5"}>
                                    <p className={"text-left italic text-bold"}>
                                        Life is short, we made more accomplished.
                                    </p>
                                    <p className={"text-left"}>
                                        Our solutions put accomplishment of task as short as possible for user.  More is achieved in the same period of time with reduced waiting time.
                                    </p>
                                    <p className={"text-left"}>
                                        Less waiting time begets less stressful experience for life to be fully enjoyed with our loved ones.
                                    </p>
                                </div>
                            </div>
                            <div className={"box flex flex-col space-y-6 lg:space-y-10"}>
                                <img alt={"illust3"} className={"w-20 lg:flex md:w-24 object-contain"} src={illust3}/>
                                <h2 className="text-h2-green text-2xl lg:text-[40px]">
                                    Secure
                                </h2>
                                <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                                <div className={"flex flex-col space-y-5"}>
                                    <p className={"text-left italic text-bold"}>
                                        Life is full of anxiety, we made it safe.
                                    </p>
                                    <p className={"text-left"}>
                                        Our solutions put security as the main pillar in ensuring peace of mind. Designs of our solutions emphasise security of financially sensitive information.
                                    </p>
                                    <p className={"text-left"}>
                                        With security, fear dissipates and peace of mind beget more quality of life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div id={"section4"} className="z-10 w-full h-fit section4 flex flex-row justify-left">

                    <div id={""} className={"relative py-10 my-auto lg:py-20 w-full h-auto flex flex-col space-y-16 lg:space-y-10"}  >
                        <div className={"flex flex-col flex-1 lg:flex-row space-x-0 lg:space-x-10 justify-between w-full"}>
                            <div className={"py-10 lg:flex w-10/12"} >
                                <img alt={"content1"} className={"lg:flex h-auto flex-1 object-cover md:w-8/12"} src={content1}/>
                            </div>

                            <div className={"flex flex-col space-y-5 justify-center align-middle px-10"}>
                                <p className={"text-h2-white leading-relaxed"}>
                                    Our Vision
                                </p>
                                <p className={"text-left text-white tracking-wide leading-relaxed"}>
                                    We believe financial inclusion is the blue ocean of the financial services industry in Indonesia. We strive to provide the tools and the services to bridge the financial divide between the haves and the haves not.
                                </p>
                                <p className={"text-left text-white tracking-wide leading-relaxed"}>
                                    Through this vision we define ourselves to create an integrated solutions based on life imperatives with financial services as the enabler not the definer.
                                </p>
                                <p className={"text-left text-white tracking-wide leading-relaxed"}>
                                    We seek to realize the solution for practical and commercial use before the end of the year 2025.
                                </p>
                            </div>
                        </div>
                        <div className={"flex flex-col-reverse flex-1 lg:flex-row space-x-0 lg:space-x-10 justify-between w-full lg:pl-16"}>
                            <div className={"flex flex-col space-y-5 justify-center align-middle px-10"}>
                                <p className={"text-h2-white leading-relaxed"}>
                                    Our Mission
                                </p>
                                <p className={"text-left text-white tracking-wide leading-relaxed"}>
                                    Indonesia is one of the countries in the world where the level of financial inclusion is still low, while on the other hand technology adoption is one of the highest in the world.
                                </p>
                                <p className={"text-left text-white tracking-wide leading-relaxed"}>
                                    Our mission is to bridge the gap with technology. Armed with our team's extensive experience in the financial services industry and a youthful passion for technological innovation, we aim to combine the two to create a superior user experience.
                                </p>
                            </div>
                            <div className={"py-10 lg:flex w-10/12"} >
                                <img alt={"content2"} className={"lg:flex h-auto flex-1 object-cover md:w-8/12"} src={content2}/>
                            </div>
                        </div>
                        <div className={"flex flex-col flex-1 lg:flex-row space-x-0 lg:space-x-10 justify-between w-full pr-16"}>
                            <div className={"py-10 lg:flex w-10/12"} >
                                <img alt={"content3"} className={"lg:flex h-auto flex-1 object-cover md:w-8/12"} src={content3}/>
                            </div>
                            <div className={"flex flex-col space-y-5 justify-center align-middle px-10"}>
                                <p className={"text-h2-white leading-relaxed"}>
                                    Our Technology
                                </p>
                                <p className={"text-left text-white tracking-wide leading-relaxed"}>
                                    Our technology is based on a platform that has been proven and audited by the authority. It has also been subjected to external party audit against intrusion.
                                </p>
                                <p className={"text-left text-white tracking-wide leading-relaxed"}>
                                    The platform will enable an end to end solution from web based front end to mobile based until the bank end solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            <div id={"section5"} className="z-10 bg-white w-full h-fit section5 flex flex-row justify-between ">

                    <div id={""} className={"relative py-10 px-5 my-auto lg:py-10 lg:px-16 w-full h-auto flex flex-col lg:space-y-10 gap-8 lg:gap-0"} >
                        <h1 id={"section5text"} className={"text-h1 text-4xl md:text-5xl pb-0"}>
                            Our Products
                        </h1>
                        <p className={"text-left leading-relaxed md:pb-5 lg:leading-none"}>
                            We currently have 4 products under our group, with different kind of solutions provided.
                        </p>
                        <div id={"isi3"} className={"px-0 relative h-auto w-full flex flex-col lg:space-y-0 md:flex-row space-x-0 justify-start content-start lg:justify-center lg:content-center"}>
                            <div className={"my-auto h-fit w-full flex flex-col space-y-5 md:space-y-0 md:flex-row justify-between space-x-0 gap-x-10"}>
                                <div id={"section41"} className={"drop-shadow-xl bg-white py-8 px-8 rounded-lg border border-green-adiwisista h-90 relative flex flex-row w-full space-x-24 justify-between"}>
                                    <div className={"flex flex-col space-y-5"}>
                                        <img alt={"product1"} className={"w-fit h-52"} src={product1}/>
                                        <img alt={"content4"} className={"w-fit h-16"} src={content4}/>
                                        <p className="text-header uppercase text-lg lg:text-[24px]">Fintech</p>
                                        <p id={"danaiidtextview"} className={"text-left tracking-wide leading-relaxed"}>
                                            A financial service solution to provide IT-Based P2P Lending service. Registered and supervised under Financial Services Authority (OJK).
                                        </p>
                                        <a className={"text-sm text-bold text-[#29ABE2] flex flex-row gap-1"} href='https://danai.id/' target="_blank" rel="noreferrer">Visit Website
                                            <img src={arrow} alt="link"/>
                                        </a>
                                    </div>
                                </div>
                                <div id={"section42"} className={"drop-shadow-xl bg-white py-8 px-8 rounded-lg border border-green-adiwisista h-90 relative flex flex-row w-full space-x-24 justify-between"}>
                                    <div className={"flex flex-col space-y-5"}>
                                        <img alt={"product2"} className={"w-fit h-52"} src={product2}/>
                                        <img alt={"content4"} className={"w-fit h-16"} src={content5}/>
                                        <p className="text-header uppercase text-lg lg:text-[24px]">Digital Venture</p>
                                        <p id={"danaitextview"} className={"text-left tracking-wide leading-relaxed"}>
                                            A Super-App, all-in-one financial services in hand.
                                        </p>
                                        <div className={"flex flex-wrap w-6/12 md:w-fit space-y-2 md:space-x-2 md:space-y-0"}>
                                            <a className={"w-36 h-fit"} href='https://play.google.com/store/apps/details?id=com.adiwisista.project&hl=id&gl=US' target="_blank" rel="noreferrer">
                                                <img  src={playstore} alt="playstore"/>
                                            </a>
                                            <a className={"w-40 sm:w-fit align-center"} href='https://apps.apple.com/us/app/danai-id-p2p-lending/id1503276449' target="_blank" rel="noreferrer">
                                                <img src={appstore} alt="appstore"/>
                                            </a>
                                            
                                        </div>
                                        {/* <a className={"text-sm text-bold text-[#29ABE2] flex flex-row gap-1"} href='https://apps.apple.com/us/app/danai-id-p2p-lending/id1503276449' target="_blank" rel="noreferrer">Go to AppStore
                                            <img src={arrow} alt="link"/>
                                        </a>
                                        <a className={"text-sm text-bold text-[#29ABE2] flex flex-row gap-1"} href='https://play.google.com/store/apps/details?id=com.adiwisista.project&hl=id&gl=US' target="_blank" rel="noreferrer">Go to PlayStore
                                            <img src={arrow} alt="link"/>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id={"isi4"} className={"px-0 pb-10 relative h-auto w-full flex flex-col lg:space-y-0 md:flex-row space-x-0 justify-start content-start lg:justify-center lg:content-center"}>
                            <div className={"my-auto h-fit w-full flex flex-col space-y-5 md:space-y-0 md:flex-row justify-between space-x-0 gap-x-10"}>
                                <div id={"section43"} className={"drop-shadow-xl bg-white py-8 px-8 rounded-lg border border-green-adiwisista h-90 relative flex flex-row w-full space-x-24 justify-between"}>
                                    <div className={"flex flex-col space-y-5"}>
                                        <img alt={"product3"} className={"w-fit h-52"} src={product3}/>
                                        <img alt={"content6"} className={"w-fit h-16"} src={content6}/>
                                        <p className="text-header uppercase text-lg lg:text-[24px]">HR Information System</p>
                                        <p id={"hrustextview"} className={"text-left tracking-wide leading-relaxed"}>
                                            A cloud-based solution for payroll administration for employees of SMEs, with lots of interesting & integrated HR features.
                                        </p>
                                        <a className={"text-sm text-bold text-[#29ABE2] flex flex-row gap-1"} href='https://hrus.online/' target="_blank" rel="noreferrer">Visit Website
                                            <img src={arrow} alt="link"/>
                                        </a>
                                    </div>
                                </div>
                                <div id={"section44"} className={"drop-shadow-xl bg-white py-8 px-8 rounded-lg border border-green-adiwisista h-90 relative flex flex-row w-full space-x-24 justify-between"}>
                                    <div className={"flex flex-col space-y-5"}>
                                        <img alt={"product4"} className={"w-fit h-fit"} src={product4}/>
                                        <img alt={"content7"} className={"w-fit h-16"} src={content7}/>
                                        <p className="text-header uppercase text-lg lg:text-[24px]">Logistic</p>
                                        <p id={"logistextview"} className={"text-left tracking-wide leading-relaxed"}>
                                            A digital solution for logistics and supply chain management service.
                                        </p>
                                        <p className={"text-sm text-bold text-[#29ABE2]"} href='https://www.google.com' target="_blank" rel="noreferrer">Coming Soon!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div id={"section7"} className="z-10 w-full h-fit section7 flex flex-row justify-between">
                    <div id={""} className={"relative py-10 px-8 lg:py-10 lg:px-20 w-full lg:w-8/12 flex flex-col space-y-5"} >
                        <h1 className={"text-h2-white"}>
                            Request Demo!
                        </h1>
                        <div className={" leading-relaxed space-y-1 flex flex-col"}>
                            <p className="text-white">To schedule a product demo with one of our product consultants, please fill in your contact details.</p>
                        </div>
                        <div className={"flex flex-col space-y-5"}>
                            <div className={"flex flex-col space-y-2"}>
                                <p className="text-white text-sm">Name</p>
                                <input placeholder={"Name"} className={"px-3 py-2 outline-0 bg-gray-100 border-b border-blue-400"} type={"text"}/>
                            </div>
                            <div className={"flex flex-col space-y-2"}>
                                <p className="text-white text-sm">Email Address</p>
                                <input placeholder={"Email Address"} className={"px-3 py-2 outline-0 bg-gray-100 border-b border-blue-400"} type={"text"}/>
                            </div>
                            <div className={"flex flex-col space-y-2"}>
                                <p className="text-white text-sm">Message</p>
                                <textarea rows={5} placeholder={"Type your message here"} className={"px-3 py-2 outline-0 bg-gray-100 border-b border-blue-400"}/>
                            </div>
                            <button className={"rounded-lg ml-auto bg-sky-500 text-white w-fit px-10 py-2"}>
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className={"hidden py-10 lg:flex w-6/12"} >
                        <img id="" alt={"section7"} className={"w-full object-cover"} src={section7}/>
                    </div>
                </div>
        </div>
    );
}
