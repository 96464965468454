import React from "react";
import { gsap } from "gsap";
import arrow from "../../Assets/Images/bi_arrow-down.svg"
const { useLayoutEffect, useRef } = React;

export default function App() {
    const app = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.to(".arrowboing", {
                translateY: "+=10",
                repeat:"-1",
                duration:"0.5",
                yoyo: "true"
            });
        }, app);

        return () => ctx.revert();
    });

    const handleClickScroll = () => {
        const element = document.getElementById('section1');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <div id={"header"} ref={app} className="App relative w-full">
            <div className={"flex flex-col relative w-full h-screen-1 lg:h-screen 2xl:h-screen"}>
                <div className={"z-20 mt-auto mb-10 mx-5 lg:mx-10 flex flex-col space-y-5 lg:space-y-10"}>
                    <p className={"text-shadow-lg text-3xl lg:text-5xl leading-relaxed font-semibold text-white"}>
                        Equitable Solutions
                        <br/>
                        for Digital Financial Life
                    </p>
                    
                    <img id={"arrow"} alt={"arrow"} src={arrow} className={"arrowboing w-10 lg:w-16"} onClick={handleClickScroll}/>
                </div>
            </div>
        </div>
    );
}

