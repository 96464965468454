import React, {useState} from 'react'
// import {Link} from "react-router-dom";
import { SidebarData } from "./SidebarData";
import * as FaIcons  from "react-icons/fa";

import "../index.css"
// import { gsap } from "gsap";
import logo from "../Assets/Images/adiwisista.svg"
import logo2 from "../Assets/Images/adiwisista _colorfull.svg"
import AnchorLink from "react-anchor-link-smooth-scroll";

const { useLayoutEffect, useRef } = React;

export default function App() {
    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)
    const app = useRef();

    useLayoutEffect(() => {

    });

    return (
        <div id="navbaranimation" ref={app} className="z-50 px-5 lg:px-10 py-5 fixed w-full max-w-screen-2xl flex flex-row items-center justify-between">
            <AnchorLink offset={() => 50} href='#header'>
                <div className={"absolute top-0 left-0 relative flex"}>
                   <img id="logo" className={"hidden top-20 left-20"} style={{}} alt={"logo adiwisista"} src={logo2}/>
                   <img id="logowhite" className={"top-20 left-20"} style={{}} alt={"logo adiwisista"} src={logo}/>
               </div>
            </AnchorLink>

            <div className='flex lg:hidden menu-bars my-auto'>
                <FaIcons.FaBars id={"burger"} className={"mix-blend-difference"} onClick={showSidebar} />
            </div>

            <div id={"textnav"} className={"hidden text-white lg:flex flex-row space-x-10"}>
                <AnchorLink offset={() => 50} href='#section1'>About</AnchorLink>
                {/* <AnchorLink offset={() => 50} href='#section4'>Adiwisista Group</AnchorLink> */}
                <AnchorLink offset={() => 50} href='#section5'>Product</AnchorLink>
                <AnchorLink offset={() => 50} href='#section7'>Contact</AnchorLink>
            </div>

            <nav className={sidebar ? 'relative nav-menu flex py-5 flex-col active' : 'relative flex flex-col py-5 nav-menu'} style={{zIndex:10000}}>

                <div className={"flex flex-col space-y-8 py-5 px-10"}>
                    <img id="logonav" className={"top-5 left-5 w-52 absolute"} style={{zIndex:"9999999999"}} alt={"logo adiwisista"} src={logo2}/>

                    <FaIcons.FaBars id={"burger"} className={"absolute right-5 top-5 mix-blend-difference"} onClick={showSidebar} />

                    {SidebarData.map((item, index) => {
                        return (
                            <div key={index} className={"w-full mx-auto"}>
                                <div onClick={showSidebar} className={"navbar-item border-b-2 border-black-adiwisista my-auto flex flex-row hover:bg-danai-blue-2 transition duration-300"}>
                                    <AnchorLink offset={() => 50} className={"text-md my-auto w-full px-5 py-2 h-10 my-auto"} href={item.path}>{item.tittle}</AnchorLink>
                                </div>
                            </div>
                        );
                    })}

                </div>

            </nav>

        </div>
    );
}

