import Home from "./Home/Header";
import Section1 from "./Home/section1"

import Footer from "./Home/footer"
import sample from "../Assets/Images/sample.mp4";
import React from "react";


export default function App() {

    return (
        <div id="home" className={"flex overflow-x-hidden flex-col w-full mx-auto"} >
            <div className={"-z-10 bg-black absolute inset-0 w-full h-screen-1 lg:h-screen"}>
                <video className={"opacity-60 object-cover w-full h-screen-1 lg:h-screen"} autoPlay={true} loop={true} muted={true}>
                    <source src={sample} type='video/mp4' />
                </video>
            </div>

            <Home/>
            <Section1/>
            <Footer/>

        </div>
    );
}


