import React from "react";
// import { gsap } from "gsap";

const { useLayoutEffect, useRef } = React;

export default function App() {
    const app = useRef();

    useLayoutEffect(() => {
        // let ctx = gsap.context(() => {
        //     gsap.to(".box", { rotation: "+=360" });
        // }, app);
        //
        // return () => ctx.revert();
    });

    return (
        <div ref={app} className="App">
        </div>
    );
}

