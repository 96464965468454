
export const SidebarData = [
    {
        tittle:'Home',
        path:'#header',

    },
    {
        tittle:'About',
        path:'#section1',

    },
    {
        tittle:'Adiwisista Group',
        path:'#section3',

    },
    {
        tittle:'Product',
        path:'#section4',

    },
    {
        tittle:'Contact',
        path:'#section5',

    },


]